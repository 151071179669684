.diff-line {
  padding: 0 0.2rem;
  margin: 0;
  white-space: pre-wrap;
  line-height: 1.5;
  min-height: 1.5em;
}
.diff-line-ins,
.diff-line ins {
  background-color: #dbffdb;
}
.diff-line-del,
.diff-line del {
  background-color: #f4bcae;
}
.diff-line ins,
.diff-line del {
  text-decoration: none;
}
